import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
const VuexLocal = new VuexPersistence({
  storage: window.localStorage
}); // https://github.com/championswimmer/vuex-persist vuex的本地持久化插件

// 授权
import {
  vuexOidcCreateStoreModule
} from 'vuex-oidc'
import {
  oidcSettings,
  oidcSettingsQQ,
  oidcAuthWeixin,
} from '../utils/oidc.js'
// 授权 end


// 通用Module
import common from './module/common';
import loginModule from './module/loginModule';
import news from './module/news';
import exhibitor from './module/exhibitor';
import linkTo from './module/linkTo';
import toolModule from './module/toolModule';
import product from './module/product';
import user from './module/user';
import banner from './module/banner';
import act from './module/act'
import search from './module/search'
import share from './module/share'
import theme from './module/theme'
import appointment from './module/appointment'
import img from './module/img'
import talk from './module/talk'
// 权限
import roleModule from './module/roleModule'
const Modele = {
  loginModule,
  news,
  linkTo,
  toolModule,
  product,
  exhibitor,
  user,
  banner,
  act,
  search,
  theme,
  appointment,
  share,
  talk,
  roleModule
};


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ...common.state, // 通用 state
    ...img.state, // 通用 state
    regOrigin: null, // 注册来源
    viewer: '', //直播间观众信息
    viewerId: '', //直播间观众ID
    share: { // 分享的配置
      url: '', // 地址
      title: '', // 标题
      desc: '', // 描述
      summary: '', // 简介
      pics: '', // 图片
    }
  },
  getters: {
    ...common.getters, // 通用 getters  
    ...img.getters, // 通用 getters  
    getRegOrigin: state => {
      return state.regOrigin;
    },
    getViewerId: state => { // 获取直播间观众ID 
      return state.viewerId;
    },
    getViewer: state => { // 获取直播间观众信息 
      return state.viewer;
    },
    getShare: state => { // 获取分享信息
      return state.share;
    },
  },
  mutations: {
    ...common.mutations, // 通用 mutations 
    ...img.mutations, // 通用 mutations 
    setRegOrigin(state, data) {
      state.regOrigin = data;
    },
    setViewerId(state, data) { // 设置直播间观众Id
      state.viewerId = data;
    },
    setViewer(state, data) { // 设置直播间观众信息
      state.viewer = data;
    },
    setShare(state, data) { // 设置分享信息
      state.share = data;
    },
  },
  modules: {
    ...Modele,
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings, {
        // namespaced: true,
        publicRoutePaths: [
          '/live-test',
          '/silentSignInCallback',
        ], // 设置不需要授权的页面
        dispatchEventsOnWindow: true
      },
    ),
    oidcStoreQQ: vuexOidcCreateStoreModule(
      oidcSettingsQQ, {
        namespaced: true
      }
    ),
  },
  actions: {
    ...common.actions, // 通用 actions
    ...img.actions // 通用 actions
  },
  plugins: [VuexLocal.plugin]
})