/* eslint-disable */
import axios from 'axios';
import config from '@assets/config.json'; // api 请求json
import qs from "qs";
import {
	Message,
	Modal
} from 'view-design';
import router from "@/router/router.js";

// 通用 Module 
const state = {}
const mutations = {
	//   setCompany(state, data) {
	//     state.company = data;
	//   },
}
const getters = {
	//   getCompany: state => {
	//     return state.company;
	//   },
}
const actions = {
	//删除预约洽谈
	delOrder({
		rootState
	}, id) {
		return new Promise((resolve, reject) => {
			axios.delete(`${config.api.api}/api/v3/exhibitorappointment?id=${id}`, rootState.header)
				.then(res => {
					if (res.status === 200 && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => console.log(err));
		});
	},
	delEnquiryNew({ rootState }, id) { // 删除询盘（新）
		return new Promise((resolve, reject) => {
			axios.delete(`${config.api.api}/api/v3/exhibitornewenquiry?id=${id}`,rootState.header)
				.then(res => {
					if (res.status === 200 && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => console.log(err));
		});
	},
	//再次编辑询盘（新）
	editEnquiryNew({
		rootState,
		commit
}, opt) {
		return new Promise((resolve, reject) => {
				axios.put(`${config.api.api}/api/v3/exhibitornewenquiry/patch?id=${opt.id}`, opt.opt, rootState.header).then(data => {
						if (data.data) {
							resolve(data.data);
						}
				}).catch((err) => {
						console.log(err);
				});
		});
},
	//编辑预约洽谈
	editOrder({
		rootState
	}, body) {
		return new Promise((resolve, reject) => {
			axios.patch(`${config.api.api}/api/v3/exhibitorappointment?id=${body.id}`, body.opt, rootState.header)
				.then(res => {
					if (res.status === 200 && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => console.log(err));
		});
	},
	//获取用户是否有密码
	getPassword({
		rootState
	}, Id) {
		return new Promise((resolve, reject) => {
			axios.get(`${config.api.auth}/api/v1/account/has/password?userId=${Id}`, rootState.header)
				.then(res => {
					if (res.status === 200 && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => console.log(err));
		});
	},
	//没有密码设置密码
	editNewPassword({
		rootState
	}, opt) {
		return new Promise((resolve, reject) => {
			axios.put(`${config.api.auth}/api/v1/account/update/password/set`, opt, rootState.header)
				.then(res => {
					if (res.status === 200 && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => console.log(err));
		});
	},
	//修改原密码
	editPassword({
		rootState
	}, opt) {
		return new Promise((resolve, reject) => {
			axios.put(`${config.api.auth}/api/v1/account/update/password`, opt, rootState.header)
				.then(res => {
					if (res.status === 200 && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => console.log(err));
		});
	},

	patchUser({
		rootState
	}, opt) { // 修改用户部分信息
		return new Promise((resolve, reject) => {
			axios.patch(`${config.api.api}/api/v1/member?id=${opt.id}`, opt, rootState.header)
				.then(({
					data
				}) => {
					resolve(data);
				});
		}).catch(err => {
			console.log(err);
		})
	},
	patchUserCategory({
		rootState
	}, opt) { // 修改用户部分信息类别
		return new Promise((resolve, reject) => {
			axios.patch(`${config.api.api}/api/v1/exhibitioninmember?id=${opt.id}`, opt.body, rootState.header)
				.then(({
					data
				}) => {
					resolve(data);
				});
		}).catch(err => {
			console.log(err);
		})
	},

	// 阅读消息
	msgRead({
		rootState
	}, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v3/notificationmessage/read?messageId=${opt.messageId}&receiverId=${opt.receiverId}`, opt, rootState.header)
				.then(res => {
					if (res.data) {
						resolve(res.data);
					}
				})
				.catch(err => console.log(err));
		});
	},
	// 提交反馈
	submitFeedBack({
		rootState
	}, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v3/feedback`, opt, rootState.header)
				.then(({
					data
				}) => {
					console.log(data, '反馈')
					if (data) {
						resolve(data);
					}
				});
		}).catch(err => {
			console.log(err);
		})
	},
	// 获取用户绑定平台信息
	getAuthInfo({
		rootState
	}, opt) {
		return new Promise((resolve, reject) => {
			axios.get(`${config.api.auth}/api/v1/account/login-provider/list?userId=${rootState.userId}`, rootState.header)
				.then(({
					data
				}) => {
					if (data) {
						resolve(data);
					}
				});
		}).catch(err => {
			console.log(err);
		})
	},
	// 删除用户绑定平台信息
	delAuthInfo({
		rootState
	}, type) {
		return new Promise((resolve, reject) => {
			axios.delete(`${config.api.auth}/api/v1/account/login-provider?userId=${rootState.userId}&loginProvider=${type}`, rootState.header)
				.then(({
					data
				}) => {
					if (data) {
						resolve(data);
					}
				});
		}).catch(err => {
			console.log(err);
		})
	},


	// 绑定用户手机
	updatePhonenumber({
		rootState
	}, opt) {
		return new Promise((resolve, reject) => {
			axios.put(`${config.api.api}/api/v3/mmeberv3/update/phonenumber`, opt, rootState.header)
				.then(({
					data
				}) => {
					if (data) {
						resolve(data);
					}
				});
		}).catch(err => {
			console.log(err);
		})
	},

}
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}