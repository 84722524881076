/* eslint-disable */
// 所有引入的第三方插件
import Vue from 'vue'
// import VueLazyload from 'vue-lazyload'
// import Vmodal from 'vue-js-modal' // https://github.com/euvl/vue-js-modal
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true;
import 'swiper/dist/css/swiper.css'
// import VideoPlayer from 'vue-video-player'
// import vcolorpicker from 'vcolorpicker' 


// //全局过滤器
// Vue.filter('dateFormat', function (value) {
//   return dateTool.format(new Date(value), "YYYY-MM-DD HH:mm:ss")
// })


Vue.filter('time_sort', function (value, formatString) {
    let now_time = new Date().getTime() / 1000;
    let c_time = new Date(value).getTime() / 1000;
    c_time -= now_time;
    if (c_time <= 0) {
        return '已结束!'
    } else {
        let dd, hh, mm, ss = null;
        dd = Math.floor(c_time / 60 / 60 / 24);
        hh = Math.floor((c_time / 60 / 60) % 24);
        mm = Math.floor((c_time / 60) % 60);
        ss = Math.floor((c_time) % 60);
        dd = dd < 10 ? '0' + dd : dd;
        hh = hh < 10 ? '0' + hh : hh;
        mm = mm < 10 ? '0' + mm : mm;
        ss = ss < 10 ? '0' + ss : ss;
        return '剩余 ' + dd + "天" + hh + "小时" + mm + "分" + ss + "秒";
    }
})

Vue.filter('end_time', function (value, formatString) {
    let now_time = new Date().getTime() / 1000;
    let c_time = new Date(value.endDate).getTime() / 1000;
    let s_time = new Date(value.startData).getTime() / 1000;
    c_time -= now_time;
    s_time -=now_time;
    if (c_time <= 0) {
        return '展会已结束';
    } else if(s_time>0) {
        let dd = null;
        dd = Math.ceil(s_time / 60 / 60 / 24);
        return `距开幕还有${dd}天`;
    }else{
        return '展会进行中';
    }
})

// Vue.use(VueLazyload, {
//   preLoad: 1.3, // 预加载
//   error: 'dist/error.png',  // 图片显示错误图片
//   loading: 'dist/loading.gif', // 图片loading图片
//   attempt: 1 // 重新加载次数
// })
Vue.use(VueAwesomeSwiper)
// Vue.use(Vmodal, { dynamic: true }) // 支持动态创建modal 动态创建的时候 一定要在当前组件下面引入此标签 <modals-container />  
// Vue.use(vcolorpicker)
Vue.use(VueClipboard) //复制链接到剪切板
// // 添加时间过滤器 引入moment.js
// Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
//   return moment(dataStr).format(pattern);
// });