const layout = () => import("../views/pages/layout.vue"); // 登录
const home = () => import("../views/pages/home/home.vue"); // 首页
const about = () => import("../views/pages/about.vue"); // 关于
const login = () => import("../views/pages/login/login.vue"); // 登录
const business = () => import("../views/pages/business/business.vue");
const activity = () => import("../views/pages/activity/activity.vue");
const activityDetails = () => import("../views/pages/activity/pages/activity-details.vue");
const activityNolive = () => import("../views/pages/activity/pages/activity-details-nolive.vue");
const activityJoin = () => import("../views/pages/activity/pages/activity-join.vue");
const product = () => import("../views/pages/product/product.vue");
const exhibitor = () => import("../views/pages/exhibitor/exhibitor.vue");
const tour = () => import("../views/pages/tour/tour.vue");
const tourBig = () => import("../views/pages/tour-big/tour.vue");
const components = () => import("../views/components/index.vue");
const exhibitorDetail = () => import("../views/pages/exhibitor/exhibitor-detail/index");
const productDetail = () => import("../views/pages/product/product-detail/index");

const demand = () => import("../views/pages/demand/demand.vue");

// 资讯
const news = () => import("../views/pages/news/news.vue");
const newsDetails = () => import("../views/pages/news/news-details.vue");

// 用户
const userCenter = () => import("../views/pages/user/index.vue"); // 个人中心
const userHome = () => import("../views/pages/user/home.vue"); // 个人中心 首页
const myCard = () => import("../views/pages/user/my-card.vue");
const myOrder = () => import("../views/pages/user/my-order.vue");
const myNeed = () => import("../views/pages/user/my-need.vue");
const myAct = () => import("../views/pages/user/my-act.vue");
const myfollow = () => import("../views/pages/user/my-follow.vue");
const myMsg = () => import("../views/pages/user/my-msg.vue");
const customer = () => import("../views/pages/user/my-customer.vue");
const myIdea = () => import("../views/pages/user/my-idea.vue");
const myInfo = () => import("../views/pages/user/my-info.vue");
const bindAuth = () => import("../views/pages/user/my-bindAuth.vue"); // 绑定授权
const myPassword = () => import("../views/pages/user/my-password/my-password.vue");
const PasswordUser = () => import("../views/pages/user/my-password/user.vue"); //更改密码
const PasswordBind = () => import("../views/pages/user/my-password/bind.vue");
const PasswordBindEdit = () => import("../views/pages/user/my-password/bind-edit.vue");
const myinquiry = () => import("../views/pages/user/my-inquiry.vue");
// 注册
const regStep = () => import("../views/pages/login/reg/reg-step1.vue");
const regInfo = () => import("../views/pages/login/reg/reg-info.vue");
const regFinish = () => import("../views/pages/login/reg/reg-finish.vue");
const regCustom = () => import("../views/pages/login/reg/reg-custom.vue");

// 授权
const redirectUrl = () => import("../views/pages/auth/redirectUrl.vue");
const auth = () => import("../views/pages/auth/auth.vue");
const authBind = () => import("../views/pages/auth/auth-bind.vue");
const authBindphone = () => import("../views/pages/auth/auth-bindphone.vue");
const OidcCallback = () => import("../views/pages/auth/OidcCallback.vue");
const silentSignInCallback = () => import("../views/pages/auth/silentSignInCallback.vue");
const demandDetail = () => import("../views/pages/demand/demand-detail/demand-detail.vue");
//导航页
const Navigation = () => import("../views/pages/Navigation.vue");
const err404 = () => import("../views/auth/404.vue");
const err500 = () => import("../views/auth/500.vue");
const user = [
  {
    path: "/user",
    name: "user",
    redirect: "/user/index",
    component: userCenter,
    children: [
      {
        path: "index",
        name: "index",
        component: userHome,
      },
      {
        path: "card",
        name: "card",
        component: myCard,
      },
      {
        path: "order",
        name: "order",
        component: myOrder,
      },
      {
        path: "need",
        name: "need",
        component: myNeed,
      },
      {
        path: "act",
        name: "act",
        component: myAct,
      },
      {
        path: "follow",
        name: "follow",
        component: myfollow,
      },
      {
        path: "msg",
        name: "msg",
        component: myMsg,
      },
      {
        path: "customer",
        name: "customer",
        component: customer,
      },
      {
        path: "idea",
        name: "idea",
        component: myIdea,
      },
      {
        path: "info",
        name: "info",
        component: myInfo,
      },
      {
        path: "bindAuth",
        name: "bindAuth",
        component: bindAuth,
      },
      {
        path: "inquiry",
        name: "inquiry",
        component: myinquiry,
      },
      {
        path: "password",
        name: "password",
        redirect: "/user/password/user",
        component: myPassword,
        children: [
          {
            path: "user",
            name: "user",
            component: PasswordUser,
          },
          {
            path: "bind",
            name: "bind",
            component: PasswordBind,
          },
          {
            path: "bind-edit",
            name: "bind-edit",
            component: PasswordBindEdit,
          },
        ],
      },
    ],
  },
];

const liveRouter = [
  {
    path: "/home",
    name: "home",
    redirect: "/home/index",
    component: layout,
    children: [
      {
        path: "index/:id",
        name: "index",
        component: home,
      },
      {
        path: "business",
        name: "business",
        component: business,
      },
      {
        path: "activity/:id",
        name: "activity",
        component: activity,
      },
      {
        path: "activity-details",
        name: "activity-details",
        component: activityDetails,
      },
      {
        path: "activity-nolive",
        name: "activity-nolive",
        component: activityNolive,
      },
      {
        path: "activity-join",
        name: "activity-join",
        component: activityJoin,
      },
      {
        path: "product/:id",
        name: "product",
        component: product,
      },
      {
        path: "demand/:id",
        name: "demand",
        component: demand,
      },
      {
        path: "exhibitor/:id",
        name: "exhibitor",
        component: exhibitor,
      },
      {
        path: "/exhibitor-detail/:id",
        name: "exhibitor-detail",
        component: exhibitorDetail,
      },
      {
        path: "/product-detail",
        name: "product-detail",
        component: productDetail,
      },
      {
        path: "news/:id",
        name: "news",
        component: news,
      },
      {
        path: "newsDetails",
        name: "newsDetails",
        component: newsDetails,
      },
      {
        path: "tour/:id",
        name: "tour",
        component: tour,
      },
      {
        path: "tour-big/:id",
        name: "tour-big",
        component: tourBig,
      },
      {
        path: "about/:id",
        name: "about",
        component: about,
      },
      {
        path: "demandDetail",
        name: "demand-Detail",
        component: demandDetail,
      },
      ...user,
    ],
  },
  {
    path: "/login/:id",
    name: "login",
    component: login,
  },
  {
    path: "/regStep/:id",
    name: "regStep",
    component: regStep,
  },
  {
    path: "/regInfo/:id",
    name: "regInfo",
    component: regInfo,
  },
  {
    path: "/regCustom/:id",
    name: "regCustom",
    component: regCustom,
  },
  {
    path: "/regFinish/:id",
    name: "regFinish",
    component: regFinish,
  },
  {
    path: "/components",
    name: "components",
    component: components,
  },
  {
    path: "/redirectUrl",
    name: "redirectUrl",
    component: redirectUrl,
    meta: {
      auth: true,
      isShare: false,
    },
  },
  {
    path: "/OidcCallback",
    name: "/OidcCallback",
    component: OidcCallback,
    meta: {
      auth: true,
      isShare: false,
    },
  },
  {
    path: "/silentSignInCallback",
    name: "/silentSignInCallback",
    component: silentSignInCallback,
    meta: {
      auth: true,
      isShare: false,
    },
  },
  {
    path: "/auth",
    name: "/auth",
    component: auth,
    meta: {
      auth: true,
      isShare: false,
    },
  },
  {
    path: "/auth-bind",
    name: "/auth-bind",
    component: authBind,
    meta: {
      auth: true,
    },
  },
  {
    path: "/auth-bindphone",
    name: "/auth-bindphone",
    component: authBindphone,
    meta: {
      auth: true,
    },
  },
  {
    path: "/Navigation",
    name: "Navigation",
    component: Navigation,
  },
  {
    path: "/404",
    name: "404",
    component: err404,
  },
  {
    path: "/500",
    name: "500",
    component: err500,
  },
];

export { liveRouter };
