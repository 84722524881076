/* eslint-disable */
import axios from 'axios';
import config from '@assets/config.json'; // api 请求json
import qs from "qs";
import { Message, Modal } from 'view-design';
import router from "@/router/router.js";

// 通用 Module 
const state = {
}
const mutations = {
  //   setCompany(state, data) {
  //     state.company = data;
  //   },
}
const getters = {
  //   getCompany: state => {
  //     return state.company;
  //   },
}
const actions = {
      addEnquiryNew({ rootState }, body) { // 添加询盘（新）
        return new Promise((resolve, reject) => {
          axios.post(`${config.api.api}/api/v3/exhibitornewenquiry`, body, rootState.header)
            .then(res => {
              if (res.status === 200 && res.data) {
                resolve(res.data);
              }
            })
            .catch(err => console.log(err));
        });
      },
      
      // 添加预约洽谈
      addOrder({
        rootState,
        commit
      }, opt) {
        return new Promise((resolve, reject) => {
          axios.post(`${config.api.api}/api/v3/exhibitorappointment`, opt, rootState.header).then(data => {
            if (data.data) {
              resolve(data.data);
            }
          }).catch((err) => {
            console.log(err);
          });
        })
      },
      //点赞展商
      exLike({ rootState }, body) {
        return new Promise((resolve, reject) => {
          axios.post(`${config.api.api}/api/v3/exhibitorlike`, body, rootState.header)
            .then(res => {
              // console.log(res, 'res123')
              if (res.status === 200 && res.data) {
                resolve(res.data);
              }
            })
            .catch(err => console.log(err));
        })
      },
      addCompLove({ rootState }, body){ // 添加展商关注
        return new Promise((resolve, reject) => {
          axios.post(`${config.api.api}/api/v1/exhibitor/follow`, body, rootState.header)
            .then(res => {
              if (res.status === 200 && res.data) {
                resolve(res.data);
              }
            })
            .catch(err => console.log(err));
        });
      },
      delCompLove({ rootState }, body) { // 取消展商关注
        return new Promise((resolve, reject) => {
          axios.delete(`${config.api.api}/api/v1/exhibitor/follow`, { data: body, ...rootState.header })
            .then(res => {
              if (res.status === 200 && res.data) {
                resolve(res.data);
              }
            })
            .catch(err => console.log(err));
        });
      },
      addAppointmentNumber({
        rootState,
        commit
      }, id) {
        return new Promise((resolve, reject) => {
          axios.post(`${config.api.api}/api/v3/exhibitorappointmentagora/add/number?exhibitorId=${id}`, {
            exhibitorId: id
          }, rootState.header).then(data => {
            if (data.data) {
              resolve(data.data);
            }
          }).catch((err) => {
            console.log(err);
          });
        });
      },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} 
