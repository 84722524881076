/* eslint-disable */
import axios from 'axios';
import config from '@assets/config.json'; // api 请求json
import qs from "qs";
import { Message, Modal } from 'view-design';
import router from "@/router/router.js";

// 通用 Module 
const state = {
}
const mutations = {
//   setCompany(state, data) {
//     state.company = data;
//   },
}
const getters = {
//   getCompany: state => {
//     return state.company;
//   },
}
const actions = {

    // 获取资讯列表
    getNewsList({ rootState, commit }, opt) {
        return new Promise((resolve, reject) => {
        axios.post(`${config.api.api}/api/v3/article/page`, opt,rootState.header).then(data => {
            if (data.data) {
            resolve(data.data);
            } else {
            resolve(null);
            }
        }).catch((err) => {
            console.log(err.response.data);
        });
        });
    },
    // 获取资讯详情
    getNewsDetails({ rootState, commit }, id) {
        return new Promise((resolve, reject) => {
        axios.get(`${config.api.api}/api/v3/article?id=${id}`, rootState.header).then(data => {
            if (data.data) {
            resolve(data.data);
            } else {
            resolve(null);
            }
        }).catch((err) => {
            console.log(err.response.data);
        });
        });
    },

    //发布供需
    supplyDemand({
        rootState
    }, opt) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.api}/api/v3/supplydemand`, opt, rootState.header).then(({
                data
            }) => {
                if (data) {
                    resolve(data);
                }
            });
        }).catch(err => {
            console.log(err);
        });
    },
    //关注供需
    supplyFollower({
        rootState
    }, opt) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.api}/api/v3/supplydemandfollower`, opt, rootState.header).then(({
                  data
            }) => {
                if (data) {
                    resolve(data);
                }
            });
        }).catch(err => {
            console.log(err);
        });
    },
    //取消关注供需
    deleteSupplyFollower({
         rootState
        }, opt) {
        return new Promise((resolve, reject) => {
            axios.delete(`${config.api.api}/api/v3/supplydemandfollower/remove?supplyDemandId=${opt.supplyDemandId}&memberId=${opt.memberId}`, rootState.header).then(({
                 data
                 }) => {
                if (data) {
                    resolve(data);
                }
            });
        }).catch(err => {
            console.log(err);
        });
    },

    //删除供需
	delSupply({rootState},id){
		return new Promise((resolve, reject) => {
			axios.delete(`${config.api.api}/api/v3/supplydemand?id=${id}`,  rootState.header)
				.then(res=>{
					if(res.status === 200 && res.data) {
						resolve(res.data);
					}
				})
				.catch(err => console.log(err));
		});
    },
    //再次编辑供需
    editSupply({
        rootState,
        commit
    }, opt) {
        return new Promise((resolve, reject) => {
            axios.put(`${config.api.api}/api/v3/supplydemand/patch?id=${opt.id}`, opt, rootState.header).then(data => {
                console.log(data);
                if (data.data) {
                    resolve(data.data);
                }
            }).catch((err) => {
                console.log(err);
            });
        });
    },

}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} 
