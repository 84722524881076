
// 图片的处理
const state = {
    // 通用
    oPicXS:'?x-oss-process=style/oPicXS',                   // 小小方图    40*40    小小logo
    oPicSmall:'?x-oss-process=style/oPicSmall',             // 小方图      40*40
    oPic:'?x-oss-process=style/oPic',                       // 方图        40*40
    oPicBig:'?x-oss-process=style/oPicBig',                 // 大方图      40*40
    oAvatarSmall:'?x-oss-process=style/oAvatarSmall',       // 小头像      40*40
    oAvatar:'?x-oss-process=style/oAvatar',                 // 中头像      40*40
    oAvatarBig:'?x-oss-process=style/oAvatarBig',           // 大头像      40*40

    oPotoView:'?x-oss-process=style/oPotoView',             // 图片查看    原始大小 增加水印

    oProSmall:'?x-oss-process=style/oProSmall',             // 小产品图    200*200
    oPro:'?x-oss-process=style/oPro',                       // 中产品图    400*400
    oProBig:'?x-oss-process=style/oProBig',                 // 大产品图    600*600
    oActSmall:'?x-oss-process=style/oActSmall',             // 小活动图    255*150
    oAct:'?x-oss-process=style/oAct',                       // 中活动图    375*210
    oActBig:'?x-oss-process=style/oActBig',                 // 大活动图    750*420
    oNews:'?x-oss-process=style/oNews',                     // 小新闻资讯  150*100
    oNewsBig:'?x-oss-process=style/oNewsBig',               // 大新闻资讯  600*400
    oTopicBanner:'?x-oss-process=style/oTopicBanner',       // 顶部Banner  750*420
    oShare:'?x-oss-process=style/oShare',                   // 分享海报    750*420
    oIntro:'?x-oss-process=style/oIntro',                   // 内容页图    800*X   固定宽度，高度自适
    

    //首页
    oLiveCover:'?x-oss-process=style/oLiveCover',           // 专题直播    200*375

    //展商
    oExBanner:'?x-oss-process=style/oExBanner',             // 邀请函·Banner    750*420

    //活动
    oBgLive:'?x-oss-process=style/oBgLive',             // 详情·直播背景竖屏   750*1334
}
const getters = {
}
const mutations = {
}
const actions = {
}
export default {
  state,
  actions,
  mutations,
  getters,
}
