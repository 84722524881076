import axios from 'axios';
import config from '@assets/config.json'; // api 请求json 

// 通用 Module 
const actions = {
  // 实时消息token获取
  getMessageToken({ rootState }, opt) { 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/agora/rtm`, opt, rootState.header)
      .then(({
        data
      }) => {
        if (data) {
          resolve(data);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  },
  // 视频聊天token获取
  getVideoToken({ rootState }, opt) { 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/agora/rtc`, opt, rootState.header)
      .then(({
        data
      }) => {
        if (data) {
          resolve(data);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  },
  // 发送消息添加
  addAgoraMessage({ rootState }, opt) { 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/agoramessage`, opt, rootState.header)
      .then(({
        data
      }) => {
        if (data) {
          resolve(data);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  },
  // 改变消息状态
  upDateStateAgoraMessage({ rootState }, opt) { 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/agoramessage/update/state`, opt, rootState.header)
      .then(({
        data
      }) => {
        if (data) {
          resolve(data);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  },
  // 发起聊天通知客服
  agoraNotify({ rootState }, opt) { 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/exhibitorappointmentagora/notify`, opt, rootState.header)
      .then(({
        data
      }) => {
        if (data) {
          resolve(data);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  },
  //客服接到通知
  serviceLink({ rootState }, opt) { 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/exhibitorappointmentagora/link`, opt, rootState.header)
      .then(({
        data
      }) => {
        if (data) {
          resolve(data);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  },
   //读用户发的消息    客服调用
   readUserMsg({ rootState }, opt) { 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/agoramessage/read/user`, opt, rootState.header)
      .then(({
        data
      }) => {
        if (data) {
          resolve(data);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  },
  //读客服发的消息   用户调用
  readExhibitorMsg({ rootState }, opt) { 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/agoramessage/read/exhibitor`, opt, rootState.header)
      .then(({
        data
      }) => {
        if (data) {
          resolve(data);
        }
      });
    }).catch(err => {
      console.log(err);
    });
  },
}
export default {
  namespaced: true,
  actions,
}
