<template>
  <router-view />
</template>

<script>
export default {
  created() {},
  watch: {
    $route(to, form) {
      if (to.fullPath !== form.fullPath) {
        this.addLink("https://v1.cnzz.com/z_stat.php?id=1279371211&web_id=1279371211");
        //中文pc
        this.addLink("https://v1.cnzz.com/z_stat.php?id=1279666461&web_id=1279666461");
      }
    },
  },
  methods: {
    addLink(src) {
      const script = document.createElement("script");
      script.src = src;
      script.language = "JavaScript";
      document.body.appendChild(script);
    },
  },
  mounted() {
    (function(w, d, s, u, n, a, m) {
      w[n] =
        w[n] ||
        function() {
          (w[n].q = w[n].q || []).push(arguments);
        };
      (a = d.createElement(s)), (m = d.getElementsByTagName(s)[0]);
      a.async = 1;
      a.src = u;
      m.parentNode.insertBefore(a, m);
      a.onload = function() {
        // 初始化sdk
        // 参数 - projectId：项目Id
        w[n].init("00000000-0000-0000-0000-000000000001", {
          // 设置获取用户id方法，请根据不同情况进行更改
          getUserId: function() {
            localStorage.getItem("userId") || "anonymous";
          },
        });
      };
    })(window, document, "script", "//track.zhanshangxiu.com/js/analytics.min.js", "ana");
  },
};
</script>

<style lang="scss"></style>
