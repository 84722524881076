/* eslint-disable */
import axios from "axios";
import config from "@assets/config.json";
import qs from "qs";
import { Message } from "view-design";

// 通用 Module
const state = {
  client_id: "", //登录背景
  loginBg: "", //登录背景
  searchType: "2", // 首页搜索框 搜索类型
  searchTxt: "", // 首页搜索框 搜索文字
  selectCategories: "", // 当前选择中的展品类别
  loginType: "", // 0 用户名登录  1手机号登录 2微信登录 3QQ登录
  redirectUrl: "", // 授权后重定向地址
  login: false, // 登录状态
  token: "", // 请求token
  userId: "", // 用户Id
  user: null, // 用户信息
  host: null, // 主办信息
  hostId: "", // 主办Id
  exhibitionId: "", // 展会Id
  exhibitionInfo: null, // 展会信息
  exhibitionConfig: null, // 展会配置
  exhibitorId: "", // 参展Id
  exhibitor: "",
  header: "",
  tokenHeaders: "",
  tokenTime: 0, // token过期时间
  auth: "", // 请求token的数据
  formHeaders: {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
  uploadUrl: `${config.api.api}/api/v1/file`, // 图片上传地址
  uploadUrl2: `${config.api.api}/api/v1/file`, // 图片上传地址
  uploadVideoUrl: `${config.api.api}/api/v1/file`, // 视频上传地址
  uploadImportUrl: `${config.api.api}/api/v3/exhibitor/import?exhibitionId=`, // 视频上传地址
  refresh: true,
  //分页
  exhibitorPage: 1,
  demandPage: 1,
  productPage: 1,
  activityPage: 1,
  // demandType: '采购产品', //供需类型
  demandType: "供应产品", //供需类型
  cateArrVuex: [], //类型选择数组  展商/展品列表通用
  regionArrVuex: [], //地区选择数组  展商/展品列表通用
  regionArrNameVuex: [], //地区name数组  展商/展品列表通用
  myAdvisorySetting: null,
};
const mutations = {
  setToken(state, data) {
    if (data) {
      state.tokenHeaders = {
        headers: {
          Authorization: "Bearer " + data,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      state.header = {
        headers: {
          Authorization: "Bearer " + data,
          "Content-Type": "application/json; charset=utf-8;",
        },
      };
    }
    state.token = data;
  },
  setSearchType(state, data) {
    state.searchType = data;
  },
  setSearchTxt(state, data) {
    state.searchTxt = data;
  },
  setSelectCategories(state, data) {
    state.selectCategories = data;
  },
  setLoginType(state, data) {
    state.loginType = data;
  },
  setLoginBg(state, data) {
    state.loginBg = data;
  },
  setTokenTime(state, data) {
    state.tokenTime = data;
  },
  setRefreshStatus(state, data) {
    state.refresh = data;
  },
  setLogin(state, data) {
    state.login = data;
  },
  setUser(state, data) {
    state.user = data;
    localStorage.setItem("userId", data);
  },
  setUserId(state, data) {
    state.userId = data;
  },
  setHost(state, data) {
    state.host = data;
  },
  setAuth(state, data) {
    state.auth = data;
  },
  setExhibitionId(state, data) {
    state.exhibitionId = data;
  },
  setExhibitionInfo(state, data) {
    state.exhibitionInfo = data;
  },
  setExhibitionConfig(state, data) {
    state.exhibitionConfig = data;
  },
  setExhibitorId(state, data) {
    state.exhibitorId = data;
  },
  setExhibitor(state, data) {
    state.exhibitor = data;
  },
  setRedirectUrl(state, data) {
    state.redirectUrl = data;
  },
  //分页
  setExhibitorPage(state, data) {
    state.exhibitorPage = data;
  },
  setDemandPage(state, data) {
    state.demandPage = data;
  },
  setProductPage(state, data) {
    state.productPage = data;
  },
  setActivityPage(state, data) {
    state.activityPage = data;
  },
  setDemandType(state, data) {
    state.demandType = data;
  },
  setCateArrVuex(state, data) {
    state.cateArrVuex = data;
  },
  setRegionArrVuex(state, data) {
    state.regionArrVuex = data;
  },
  setRegionArrNameVuex(state, data) {
    state.regionArrNameVuex = data;
  },
  setMyAdvisorySetting(state, data) {
    state.myAdvisorySetting = data;
  },
};
const getters = {
  getSearchType: state => {
    return state.searchType;
  },
  getSearchTxt: state => {
    return state.searchTxt;
  },
  getSelectCategories: state => {
    return state.selectCategories;
  },
  getUploadUrl: state => {
    return state.uploadUrl;
  },
  getLoginType: state => {
    return state.loginType;
  },
  getLoginBg: state => {
    return state.loginBg;
  },
  getUploadUrl2: state => {
    return state.uploadUrl2;
  },
  getUploadVideoUrl: state => {
    return state.uploadVideoUrl;
  },
  getUploadImportUrl: state => {
    return state.uploadImportUrl;
  },
  getFormHeaders: state => {
    return state.formHeaders;
  },
  getRefreshStatus: state => {
    return state.refresh;
  },
  getTokenTime: state => {
    return state.tokenTime;
  },
  getToken: state => {
    const token = state.token || localStorage.getItem("access_token");
    state.token = token;
    return state.token;
    // return 'eyJhbGciOiJSUzI1NiIsImtpZCI6IkU2RkNGQjVGMjBEODAyOERCQTFGOEMzRTJDNUI2RDQ5IiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2MTQwNjU4MDUsImV4cCI6MTYxNTI3NTQwNSwiaXNzIjoiaHR0cHM6Ly9hdXRoLnpoYW5zaGFuZ3hpdS5jb20iLCJhdWQiOlsiYm1tLWFwaSIsInNob3dvbmxpbmUtYXBpIl0sImNsaWVudF9pZCI6InN5bmFpci1zaG93b25saW5lLWpzIiwic3ViIjoiMzZmYzNkOGUtZWNlNi00NGU3LTkyZGMtZjM3MTBlZDA4MTIyIiwiYXV0aF90aW1lIjoxNjE0MDY1ODA1LCJpZHAiOiJsb2NhbCIsImlhdCI6MTYxNDA2NTgwNSwic2NvcGUiOlsiYm1tLWFwaSIsIm9wZW5pZCIsInBob25lIiwicHJvZmlsZSIsInNob3dvbmxpbmUtYXBpIl0sImFtciI6WyJwaG9uZV9udW1iZXIiXX0.IBfyIaCrIgIIYEt8d5AEd11j0lvhCB43_xqU9F7XYoLunpQyE_a129Fv5indJLO09KfeyTXEMQKW2uoy3DWk8dq6rFxIpXTYkisL8gbOpX906ZHisfEAa0MAtomMZUMJW1gUqXgriCd5XeSOX1RoUzngVZxbgjivbbYlKcScp0oBtavuzlocNXWnYDbFdu731wLg9mM5SCkuyhSd-Sp4nwGluC2RTflb_QP2L-UTKj2gaRk6zR5BXlwmpmvPZ8U3YYwB7tfV2TE6M7nZIhNdOfTijdtk_Yk0ksgeze_vwN4-hdTnVoURSLBXoPcwBDWJHeuioG04JD83MUY584Qws'
  },
  getUser: state => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && !state.user) {
      state.user = user;
    }
    return state.user;
  },
  getUserId: state => {
    return state.userId;
  },
  getLogin: state => {
    const login = JSON.parse(localStorage.getItem("login"));
    if (login) {
      state.login = login;
    }
    return state.login;
  },
  getAuth: state => {
    return state.auth;
  },
  getExhibitionId: state => {
    return state.exhibitionId;
  },
  getExhibitionInfo: state => {
    return state.exhibitionInfo;
  },
  getExhibitionConfig: state => {
    return state.exhibitionConfig;
  },
  getMyAdvisorySetting: state => {
    return state.myAdvisorySetting;
  },
  getExhibitorId: state => {
    return state.exhibitorId;
  },
  getExhibitor: state => {
    return state.exhibitor;
  },
  getRedirectUrl: state => {
    return state.redirectUrl;
  },
  //分页
  getExhibitorPage: state => {
    return state.exhibitorPage;
  },
  getDemandPage: state => {
    return state.demandPage;
  },
  getProductPage: state => {
    return state.productPage;
  },
  getActivityPage: state => {
    return state.activityPage;
  },
  //分页
  getDemandType: state => {
    return state.demandType;
  },
  getCateArrVuex: state => {
    return state.cateArrVuex;
  },
  getRegionArrVuex: state => {
    return state.regionArrVuex;
  },
  getRegionArrNameVuex: state => {
    return state.regionArrNameVuex;
  },
  getEnabledExhibitionVisitorReg: state => {
    let bool = false;
    if (state.exhibitionConfig && state.exhibitionConfig.feature && state.exhibitionConfig.feature.enabledExhibitionVisitorReg) {
      bool = true;
    }
    return bool;
  },
  getEnabledBmm: state => {
    let bool = false;
    if (state.exhibitionConfig && state.exhibitionConfig.feature && state.exhibitionConfig.feature.enabledBmm) {
      bool = true;
    }
    return bool;
  },
  getEnabledExhibitionList: state => {
    let bool = false;
    if (state.exhibitionConfig && state.exhibitionConfig.feature && state.exhibitionConfig.feature.enabledExhibitionList) {
      bool = true;
    }
    return bool;
  },
  getDisplay: state => {
    let str = "theme1";
    if (state.exhibitionConfig && state.exhibitionConfig.display && state.exhibitionConfig.display.themeId && Number(state.exhibitionConfig.display.themeId) > 0) {
      str = "theme" + state.exhibitionConfig.display.themeId;
    }
    return str;
  },
};
const actions = {
  /**
   *  获取数据类型通用写法封装
   * @param {*} param0 
   * @param {*} data { url: '基础接口的传入字符串', opt: 分页请求的参数}
   * url替换 <控制器名称>  【 /api/v1/<控制器名称>/wherepage 】
   *opt:  pageSize 页条数
          pageIndex 页码
          sorts[] 排序属性 
            propertyName 属性名称（必须是实体属性名称，且区分大小写，如果属性名称以小写开头，请把首字母大写）
            ascending 是否升序
          wheres 条件集
            type 条件类型
              0 条件组
              1 字段
              2 在值1与值2之间
              3 不在值1与值2之间
              4 符合给定的条件
              5 不符合给定的条件
            wheres 条件对象
   */
  getWherepage({ state }, data) {
    return new Promise((resolve, reject) => {
      const defaultOpt = {
        pageSize: 1000,
        sorts: [
          {
            propertyName: "showIndex",
            ascending: true,
          },
        ],
      };
      const opt = data.opt ? Object.assign({}, defaultOpt, data.opt) : defaultOpt;
      axios
        .post(`${state.reqApi}/api/v1/${data.url}/wherepage`, opt)
        .then(data => {
          if (data.data) {
            resolve(data.data);
          }
        })
        .catch(err => {
          console.log(err.response.data);
        });
    });
  },
  getStaff({ state }, id) {
    // 获取客服
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.api.reqApi}/api/staff/GetDefault?compId=${id}`, state.header)
        .then(data => {
          if (data.data) {
            resolve(data.data);
          } else {
            resolve(null);
          }
        })
        .catch(err => {
          console.log(err.response.data);
        });
    });
  },
  uploadImages({ state }) {
    // 上传
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.reqApi}/api/upload/images`, {}, state.formHeaders)
        .then(data => {
          if (data.data) {
            if (data.data.successed) {
              Message.success("验证码已发送");
            } else {
              Message.success("验证码发送失败");
            }
            resolve(data.data);
          }
        })
        .catch(err => {
          console.log(err.response.data);
        });
    });
  },
  refreshToken({ state, rootGetters, rootState, commit }) {
    console.log("refreshToken");
    const opt = {
      grant_type: "refresh_token",
      client_id: "cloud.exhibition",
      client_secret: "secret",
      refresh_token: state.auth.refresh_token,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.auth}/connect/token`, qs.stringify(opt), rootState.formHeaders)
        .then(data => {
          if (data.data) {
            commit("setAuth", data.data, {
              root: true,
            });
            commit("setToken", data.data.access_token, {
              root: true,
            });
            commit("setTokenTime", data.data.expires_in * 1000 + new Date().getTime(), {
              root: true,
            });
            commit("setRefreshStatus", true, {
              root: true,
            });
            resolve(data.data);
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
          }
        });
    });
  },
  getSetting({ rootState, commit }, opt) {
    return new Promise((resolve, reject) => {
      // type 0： 全局、 1： 系统、 2： 唯一Id
      // scope - 作用域（ 例如展会id）
      // name - 名称（例如‘share:config’）

      // {
      //   "sensitives-word": {
      //     "name": "sensitives-word",
      //     "displayName": "敏感词"
      //   },
      //   "share:config": {
      //     "name": "share:config",
      //     "displayName": "分享设置"
      //   },
      //   "exhibition.config": {
      //     "name": "exhibition.config",
      //     "displayName": "展会设置"
      //   },
      //   "exhibition.config.room": {
      //     "name": "exhibition.config.room",
      //     "displayName": "主办直播间设置"
      //   },
      //   "exhibitor.config": {
      //     "name": "exhibitor.config",
      //     "displayName": "展商设置"
      //   },
      //   "exhibitor.config.room": {
      //     "name": "exhibitor.config.room",
      //     "displayName": "展商直播间设置"
      //   },
      //   "inmember.config": {
      //     "name": "inmember.config",
      //     "displayName": "观众设置"
      //   }
      // }

      axios
        .get(`${config.api.liveApi}/api/v1/settings/by?type=${opt.type}&scope=${opt.scope}&name=${opt.name}`, rootState.header)
        .then(({ data }) => {
          if (data) {
            resolve(data);
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
          }
        });
    });
  },
  editSetting({ rootState, commit }, opt) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${config.api.liveApi}/api/v1/settings?id=${opt.id}`, opt.opt, rootState.header)
        .then(({ data }) => {
          if (data) {
            resolve(data);
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
          }
        });
    });
  },

  getBasicSetting({ rootState, commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.api.api}/api/v1/settings/exhibition?exhibitionId=${id}`, rootState.header)
        .then(({ data }) => {
          if (data) {
            resolve(data);
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
          }
        });
    });
  },
  editBasicSetting({ rootState, commit }, opt) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${config.api.api}/api/v1/settings/exhibition/patch?exhibitionId=${opt.id}`, opt.opt, rootState.header)
        .then(({ data }) => {
          if (data) {
            resolve(data);
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
          }
        });
    });
  },
  getShorturl({ rootState, commit }, url) {
    // 获取短链接
    return new Promise((resolve, reject) => {
      axios.get("https://online-api.zhanshangxiu.com/synair/shorturl/convert?url=" + url, rootState.header).then(
        res => {
          if (res.status === 200) {
            resolve(res.data.Data);
          } else {
            reject(res.data.Data);
          }
        },
        err => {
          reject(err);
        }
      );
    });
  },
  getSettingShare({ rootState, commit }, opt) {
    return new Promise((resolve, reject) => {
      // type 0： 全局、 1： 系统、 2： 唯一Id
      // scope - 作用域（ 例如展会id）
      // name - 名称（例如‘share:config’）

      // {
      //   "share:config": {
      //     "name": "share:config",
      //     "displayName": "分享设置"
      //   },
      //   "share.exhibition.config": {
      //     "name": "share.exhibition.config",
      //     "displayName": "展会分享设置"
      //   },
      //   "share.exhibitor.config": {
      //     "name": "share.exhibitor.config",
      //     "displayName": "展商邀请函分享设置"
      //   },
      //   "share.product.config": {
      //     "name": "share.product.config",
      //     "displayName": "展品分享设置"
      //   },
      //   "share.activity.config": {
      //     "name": "share.activity.config",
      //     "displayName": "活动分享设置"
      //   },
      //   "share.theme.config": {
      //     "name": "share.theme.config",
      //     "displayName": "话题分享设置"
      //   },
      // }

      axios
        .get(`${config.api.liveApi}/api/v1/settings/by?type=${opt.type}&scope=${opt.id}&name=${opt.name}`, rootState.header)
        .then(({ data }) => {
          if (data.data) {
            resolve(data.data);
            // resolve(JSON.parse(data.data.value));
          } else {
            // resolve({content:'',des:''})
          }
        })
        .catch(err => {
          if (err.response) {
            console.log(err.response.data);
          }
        });
    });
  },

  //mice cad 阿波罗接口（展商秀接口）
  graphqlPostByZXS({ rootState }, opt) {
    // 获取主办
    let header = {
      "content-Type": "application/json; charset=utf-8;",
    };
    let name = "";
    if (opt.queryName) {
      name = opt.queryName + "";
      delete opt.queryName;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.api}/graphql${name ? "?queryName=" + name : ""}`, opt, header)
        .then(data => {
          if (data.data) {
            // 模拟信号差的情况
            // setTimeout(() => {
            //   resolve(data.data);
            // }, 2000)
            resolve(data.data);
          } else {
            resolve(null);
          }
        })
        .catch(err => {
          resolve(null);
          console.log(err);
        });
    });
  },

  //  展位图数据
  graphqlPostByTu({ rootState }, opt) {
    // 获取主办
    let header = {
      "content-Type": "application/json; charset=utf-8;",
    };
    let name = "";
    if (opt.queryName) {
      name = opt.queryName + "";
      delete opt.queryName;
    } 
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.tuApi}/graphql${name ? "?queryName=" + name : ""}`, opt, header)
        .then(data => {
          if (data.data) {
            resolve(data.data);
          } else {
            resolve(null);
          }
        })
        .catch(err => {
          resolve(null);
          console.log(err);
        });
    });
  },

  //  展位图数据 token
  graphqlPostByTu2({ rootState }, opt) {
    // 获取主办
    let token = opt.token
      ? {
          headers: {
            Authorization: `Bearer ${opt.token}`,
          },
        }
      : {};
    let header = {
      "content-Type": "application/json; charset=utf-8;",
      ...token,
    };
    let name = "";
    if (opt.queryName) {
      name = opt.queryName + ""; 
      delete opt.queryName;
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.tuApi}/graphql${name ? "?queryName=" + name : ""}`, opt.body, header)
        .then(data => {
          if (data.data) {
            resolve(data.data);
          } else {
            resolve(null);
          }
        })
        .catch(err => {
          resolve(null);
          console.log(err);
        });
    });
  },

  //mice cad 阿波罗接口（展商秀接口）
  graphqlPost({ rootState }, opt) {
    // 获取主办
    let token = opt.token
      ? {
          headers: {
            Authorization: `Bearer ${opt.token}`,
          },
        }
      : {};
    let header = {
      "content-Type": "application/json; charset=utf-8;",
      ...token,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.api}/graphql`, opt.opt, header)
        .then(({ data }) => {
          if (data) {
            resolve(data);
          } else {
            resolve(null);
          }
        })
        .catch(err => {
          resolve(null);
          console.log(err);
        });
    });
  },

  getMsgCode({}, phone) {
    // 获取短信验证码
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.api}/api/v1/sms/verificationcode`, {
          phoneNumber: phone,
        })
        .then(data => {
          if (data.data) {
            if (data.data.result) {
              Message.success("验证码已发送");
            } else {
              Message.success("验证码发送失败");
            }
            resolve(data.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    });
  },
  validateCode({}, opt) {
    // 效验短信验证码
    return new Promise((resolve, reject) => {
      axios
        .get(`${config.api.api}/api/v1/verificationcode/valid?key=${opt.key}&code=${opt.code}`)
        .then(data => {
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
        });
    });
  },

  regUser({}, opt) {
    // 用户注册
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.api}/api/v3/mmeberv3/reg/visitor/account`, opt)
        .then(data => {
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
        });
    });
  },
  regUserByVisitor({ rootState }, opt) {
    // 用户注册
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.api}/api/v3/mmeberv3/reg/visitor`, opt, rootState.header)
        .then(data => {
          resolve(data.data);
        })
        .catch(err => {
          console.log(err);
        });
    });
  },
  // 添加自定义表单数据
  addCustomForm({ rootState }, opt) {
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/formwrite`, opt, rootState.header).then(data => {
        resolve(data.data);
      });
    }).catch(err => {
      resolve(false);
      console.log(err);
    });
  },
  // 获取观众自定义表单
  async getCustomForm({ rootState, dispatch }, opt) {
    let query = `
        query($itemId: String!,$formType: String!){
          getForm(itemId: $itemId, formType: $formType){
            id
            items{
              attributes
              childrens{
                attributes
                defaultValue
                description
                formId
                id
                label
                parentCount
                parentId
                showIndex
                tagType
              }
              defaultValue
              description
              formId
              id
              label
              parentCount
              parentId
              showIndex
              tagType
            }
          }
        }
      `;
    let body = {
      query: query,
      variables: {
        itemId: opt.itemId,
        formType: opt.formType,
      },
    };
    let data = await dispatch("graphqlPostByZXS", body);
    return new Promise((resolve, reject) => {
      if (data.data) {
        resolve(data.data);
      } else {
        resolve(false);
      }
    });
  },

  async getCategory({ rootState, dispatch }, body) {
    // 获取展品类别
    let query = `query($hostId:Guid!){
          categories(hostId:$hostId){
              childrens{
                  id
                  name
                  nameEn
                  parentId
                  shortName
                  shortNameEn
                  showIndex
              }
              id
              name
              nameEn
              parentId
              shortName
              shortNameEn
              showIndex
          }
        }`;
    let opt = {
      query: query,
      variables: {
        hostId: rootState.exhibitionId,
      },
    };
    let data = await dispatch("graphqlPostByZXS", opt);
    return new Promise((resolve, reject) => {
      if (data.data) {
        resolve(data.data);
      } else {
        resolve(false);
      }
    });
  },
  // 获取展馆数据
  async getHall({ rootState, dispatch }, body) {
    let query = `query($exhibitionId: Guid){
            getAllExHall(exhibitionId:$exhibitionId){
              description
              exhibitionId
              id
              name
              nameEn
              shortName
              shortNameEn
              showIndex
          }
        }`;
    let opt = {
      query: query,
      variables: {
        exhibitionId: rootState.exhibitionId,
      },
    };
    let data = await dispatch("graphqlPostByZXS", opt);
    return new Promise((resolve, reject) => {
      if (data.data) {
        resolve(data.data);
      } else {
        resolve(false);
      }
    });
  },
  unverifiedDialog({ rootState, dispatch }, that) {
    if (rootState.token && rootState.user && rootState.user.userId) {
      console.log(rootState.token, rootState.user, rootState.user.userId, "rootState.user.userRole");
      that.$Modal.confirm({
        title: " 前往确认资料",
        content: "请确认您的个人信息并补充您感兴趣产品类别，谢谢！",
        okText: "前往确认",
        onOk: () => {
          dispatch("linkTo/linkToRegSteps", {
            root: true,
          });
          // that.$router.push('/regstep')
        },
        onCancel: () => {},
      });
    } else {
      that.$Modal.confirm({
        title: "未登录用户",
        content: "请前往登录再进行操作",
        okText: "前往登录",
        onOk: () => {
          dispatch("linkTo/linkToLogin", {
            root: true,
          });
          // that.$router.push('/regstep')
        },
        onCancel: () => {},
      });
    }
  },
  // 添加浏览记录
  addAllView({ rootState, commit }, opt) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${config.api.api}/api/v3/addview`, opt, rootState.header)
        .then(data => {
          if (data.data) {
            resolve(data.data);
          }
        })
        .catch(err => {
          console.log(err);
        });
    });
  },
};
export default {
  state,
  actions,
  mutations,
  getters,
};
