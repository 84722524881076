import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import './router/http' // 引入axios
import './other/ivewImport' // ivew 模块引入  
import './other/plugin' // 所有引入的第三方插件
import directives from './directives/directives.js';
import axios from 'axios'

axios.prototype.$https = axios
import './assets/scss/index.scss'
import './assets/scss/my-theme/my-theme.less'
import VueCookies from 'vue-cookies'
import VideoPlayer from 'vue-video-player';
import moment from 'moment';
// 第三方
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
import myService from './assets/js/tool.js'; // 工具函数

import Mixin from './utils/mixin';
Vue.mixin(Mixin);

import {
  hasRole
}
from './utils/roleDirect'
const Plugins = [hasRole]
Plugins.map((plugin) => {
  Vue.use(plugin)
})

Vue.config.productionTip = false
Vue.use(router)
Vue.use(VueCookies)
Vue.use(VideoPlayer);
Vue.use(myService);
Vue.prototype.$moment = moment;
Vue.prototype.$Bus = new Vue;

// 添加时间过滤器 引入moment.js
Vue.filter('moment', function (dataStr, pattern = 'YYYY/MM/DD HH:mm') {
  return moment(dataStr).format(pattern);
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')