/* eslint-disable */
import axios from 'axios';
import config from '@assets/config.json'; // api 请求json
import qs from "qs";
import { Message, Modal } from 'view-design';
import router from "@/router/router.js";

// 通用 Module
const state = {
}
const mutations = {
//   setCompany(state, data) {
//     state.company = data;
//   },
}
const getters = {
//   getCompany: state => {
//     return state.company;
//   },
}
const actions = {

    // 产品点赞
    ProductLike({ rootState, commit }, opt) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.api}/api/v3/productlike`, opt,rootState.header).then(data => {
                if (data.data) {
                    resolve(data.data);
                } else {
                    resolve(null);
                }
            }).catch((err) => {
                console.log(err.response.data);
            });
        });
    },
    // 产品关注
    ProductFollow({ rootState, commit }, opt) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.api}/api/v1/productfollower`, opt,rootState.header).then(data => {
                if (data.data) {
                    resolve(data.data);
                } else {
                    resolve(null);
                }
            }).catch((err) => {
                console.log(err.response.data);
            });
        });
    }

}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
