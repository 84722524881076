export default {
  computed: {
    hasRole() {
      return function (roleName) {
        let hasRole = false;
        if (this.$store.state.roleModule.roleMenu && this.$store.state.roleModule.roleMenu.indexOf(roleName) !== -1) {
          hasRole = true;
        }
        return hasRole;
      }
    }
  },
  methods: {
    /**
     * 此方法为判断权限 全局Mixins roleName为传入的唯一权限字符
     * @param {*} roleName  roleName为传入的唯一权限字符
     * @returns 返回布尔值 boolean
     */
    getRoleHandle(roleName) {
      let hasRole = false;
      if (this.$store.state.roleModule.roleMenu && this.$store.state.roleModule.roleMenu.indexOf(roleName) !== -1) {
        hasRole = true;
      }
      if (!hasRole) {
        this.$Message.destroy();
        this.$Message.warning({
          content: "主办暂未开放此服务",
          duration: 2,
        });
      }
      console.log("roleName", roleName);
      console.log("hasRole", hasRole);
      return hasRole;
    },
    getRoleHandleByEx(roleName) {
      let hasRole = false;
      if (this.$store.state.roleModule.roleMenu && this.$store.state.roleModule.roleMenu.indexOf(roleName) !== -1) {
        hasRole = true;
      }
      if (!hasRole) {
        this.$Message.destroy();
        this.$Message.warning({
          content: "主办暂未开放此服务",
          duration: 2,
        });
      }
      console.log("roleName", roleName);
      console.log("hasRole", hasRole);
      return hasRole;
    },
  }
};