import Vue from 'vue';

const loadImg = Vue.directive('load', {
    bind: function (el, binding) {
        if (el.src) {
            el.src += `?x-oss-process=style/${binding.value}`;
        }
    },
    update: function (el, binding, vnode) {
        if (el.src && el.src.search(/\?x-oss-process/) === -1) {
            el.src += `?x-oss-process=style/${binding.value}`;
        }
    }
})
const watermark = Vue.directive('watermark', {
    bind: function (el, binding, vnode) {
        if (el && el.getAttribute('large')) {
            const large = el.getAttribute('large');
            el.setAttribute('large', large + `?x-oss-process=style/${binding.value}`);
        }
    },
})
const drag = Vue.directive('drag',{
    //1.指令绑定到元素上回立刻执行bind函数，只执行一次
    //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
    //3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
    bind:function(el){},
    //inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
    inserted:function(el){  
      el.onmousedown = function (e) {
        var disx = e.pageX - el.offsetLeft;
        var disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
            let elW=el.offsetWidth;
            let elH=el.offsetHeight
            if(e.pageX - disx < 0 ){
                //超出左边
                el.style.left =0    
            }else if((e.pageX - disx) + elW > document.body.offsetWidth){
                //超出右边
                el.style.left =(document.body.offsetWidth - elW)+'px'
            }else{
                el.style.left = e.pageX - disx + 'px';
            }
            if(e.pageY - disy < 0){
                //超出上面
                el.style.top =0    
            }else if((e.pageY - disy) + elH > document.documentElement.clientHeight){
                //超出下面
                el.style.top =(document.documentElement.clientHeight - elH)+'px'  
            }else{
                el.style.top = e.pageY - disy + 'px';
            }
            // console.log((e.pageY - disy) + elH,elH,document.documentElement.clientHeight,'width')
        }
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null;
        }
      }
    },
    //当VNode更新的时候会执行updated，可以触发多次
    updated:function(el) {}
  })
export {loadImg, watermark,drag}
