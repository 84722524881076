import axios from 'axios';
import config from '@assets/config.json'; // api 请求json
import qs from "qs";

const state = {
  activityList: [], // 活动信息,
}
const mutations = {
  setActivity(state, data) {
    state.activityList = data;
  },
}
const getters = {
  getActivity: state => {
    return state.activityList;
  },
}
const actions = {
  getCountry({
    rootState,
    commit
  }, opt) { // 获取国家省份城市
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/country/countries`, opt, rootState.header).then(({
        data
      }) => {
        if (data) {
          resolve(data);
        };
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  getCountrySub({
    rootState,
    commit
  }, opt) { // 获取国家省份城市
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v1/country/find`, opt, rootState.header).then(({
        data
      }) => {
        if (data) {
          resolve(data);
        };
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  getMapValue({
    rootState
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v1/mapvalue`, opt, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  getMapValueByKey({
    rootState
  }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/mapvalue?id=${id}`, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  getLiveRoomCode({
    rootState,
    commit
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v1/wechat/weapp/qrcode/unlimit`, opt, rootState.header)
        .then(({
          data
        }) => {
          if (data) {
            resolve(data);
          } else {
            resolve('');
          }
        }).catch((err) => {
          resolve(false);
          console.log(err);
        });
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}