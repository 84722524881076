import axios from 'axios';
import config from '@assets/config.json'; // api 请求json

const state = {
}
const mutations = {
}
const getters = {
}
const actions = {
    // 添加活动围观
    addActFollower({ rootState, commit }, opt) { 
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v3/activityview`, opt, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
    },
    // 添加活动点赞
    addActZan({ rootState, commit }, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v3/activitylike`, opt, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
    },
    // 取消活动点赞
    delActZan({ rootState, commit }, opt) {
		return new Promise((resolve, reject) => {
			axios.delete(`${config.api.api}/api/v3/activitylike/remove?activityId=${opt.activityId}&userId=${opt.userId}`, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
    },
    // 添加活动关注
    addActLove({ rootState, commit }, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v3/activityfollow`, opt, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
    },
    // 取消活动关注
    delActLove({ rootState, commit }, opt) {
		return new Promise((resolve, reject) => {
			axios.delete(`${config.api.api}/api/v3/activityfollow/remove?activityId=${opt.activityId}&userId=${opt.userId}`, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
	},
	// 检查是否能观看直播
	checkLiveView({ rootState, commit }, opt) {
		return new Promise((resolve, reject) => {
			axios.get(`${config.api.api}/api/v1/liveroom/view?roomId=${opt.roomId}&memberId=${opt.memberId}&code=${opt.code}`, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
	},
	// 密付费观看
	payForMent({ rootState, commit }, opt){
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v1/liveroom/view/payment`, opt, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
	},
	// 密码观看
	payForPassWord({ rootState, commit }, opt){
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v1/liveroom/view/password`, opt, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
	},
	// 申请上热榜
	applyhot({rootState,commit}, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v1/exhibitioninmember/apply-popularizes`,opt, rootState.header).then(data => {
			if (data.data) {
				resolve(data.data);
			} else {
				resolve(null);
			}
			}).catch((err) => {
			console.log(err);
			});
		});
	},
	// 申请直播
	applyLive({rootState,commit}, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v1/liveroom/apply`,opt, rootState.header).then(data => {
			if (data.data) {
				resolve(data.data);
			} else {
				resolve(null);
			}
			}).catch((err) => {
			console.log(err);
			});
		});
	},
	// 申请上焦点
	applyfocus({
			rootState,
			commit
		}, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v1/exhibitioninmember/apply-focus`,opt, rootState.header).then(data => {
			if (data.data) {
				resolve(data.data);
			} else {
				resolve(null);
			}
			}).catch((err) => {
			console.log(err);
			});
		});
	},
	// 刷礼物
	giftTo({rootState,commit}, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v1/giftgiving/to`,opt, rootState.header).then(data => {
				if (data.data) {
				resolve(data.data);
				} else {
				resolve(null);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
	},
	// 获取金豆数量
	getBanlance({rootState,commit}, id) {
		return new Promise((resolve, reject) => {
			axios.get(`${config.api.api}/api/v1/member/my-balance?memberId=${id}`, rootState.header).then(data => {
				if (data.data||data.data==0) {
				resolve(data.data);
				} else {
				resolve(null);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
	},
	// 助力 支付慕渊豆  
	helpMyDou({rootState},opt){
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v1/helplog/to`, opt,  rootState.header)
			.then(res=>{
				if(res.status === 200 && res.data) {  
					resolve(res.data);
				}else{
					resolve(false)
				}
			})
			.catch(err => console.log(err));
		});
	},
	// 活动报名
	activityReg({rootState,commit}, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v3/activityreg`,opt, rootState.header).then(data => {
			if (data.data) {
				resolve(data.data);
			} else {
				resolve(null);
			}
			}).catch((err) => {
			console.log(err);
			});
		});
	},
	// 获取获得订单
	actOrder({rootState,commit}, id) {
		return new Promise((resolve, reject) => {
			axios.get(`${config.api.api}/api/v3/activity?id=${id}`, rootState.header).then(data => {
			if (data.data) {
				resolve(data.data);
			} else {
				resolve(null);
			}
			}).catch((err) => {
			console.log(err);
			});
		});
	},
	// 获取活动详情
	getActDetail({rootState,commit}, id) {
		return new Promise((resolve, reject) => {
			axios.get(`${config.api.api}/api/v3/activity?id=${id}`, rootState.header).then(data => {
			if (data.data) {
				resolve(data.data);
			} else {
				resolve(null);
			}
			}).catch((err) => {
			console.log(err);
			});
		});
	},
	// 创建订单
	addOrder({rootState,commit}, opt) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v3/activityticketorder`, opt,rootState.header).then(data => {
			if (data.data) {
				resolve(data.data);
			} else {
				resolve(null);
			}
			}).catch((err) => {
			console.log(err);
			});
		});
	},
	//通过roomId获取前20条留言
    getBarrage({
        rootState,
        commit
    },id){
        return new Promise((resolve,reject)=>{
            axios.get(`${config.api.api}/api/v1/livecomment/top?roomId=${id}&topNum=20`, rootState.header)
            .then(data=>{
                if(data&&data.data.length){
                    resolve(data.data);
                }else{
                    resolve(false);
                }
            }).catch((err) => {
                resolve(false);
                console.log(err);
            });
        })
	},
	//直播点赞
	likeTo({ rootState }, opt) { 
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v1/liveroom/like`, opt, rootState.header).then(({
			   data
			  }) => {
			if (data) {
			  resolve(data);
			}else{
			  resolve(false)
			}
		  });
		}).catch(err => {
		  console.log(err);
		});
	},
	// 获取活动类别
    getActType({
        rootState
    }, opt) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.api}/api/v3/activitycategory/query`, opt, rootState.header).then(data => {
                if (data.data) {
                    resolve(data.data);
                } else {
                    resolve(null);
                }
            }).catch((err) => {
                console.log(err);
            });
        });
    },
}
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
} 