import axios from 'axios';
import config from '@assets/config.json'; // api 请求json 

const state = {}
const mutations = {}
const getters = {}
const actions = {

    // 获取观众自定义表单
    async getSearch({
        rootState,
        dispatch
    }, opt) {



        let query = `query {
                searchWordQuery {
                    tops(
                    itemId: "${opt.exhibitionId}"
                    origin: "${opt.origin}"
                    num: 3
                    )
                }
            } 
        `;
        let body = {
            query: query,
        };
        let data = await dispatch('graphqlPostByZXS', body, {
            root: true
        });
        return new Promise((resolve, reject) => {
            if (data.data) {
                resolve(data.data);
            } else {
                resolve(false);
            }
        })
    },

    addSearch({
        rootState
    }, opt) { // 添加活动信息
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.liveApi}/api/v3/searchword`, opt, rootState.header).then(data => { 
                if (data.status) {
                    resolve(data);
                }
            }).catch((err) => {
                console.log(err.response.data);
            });
        });
    },
}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}