/* eslint-disable */
import axios from 'axios';
import config from '@assets/config.json'; // api 请求json
import qs from "qs";
import { Message, Modal } from 'view-design';
import router from "@/router/router.js";

// 通用 Module 
const state = {
}
const mutations = {
  //   setCompany(state, data) {
  //     state.company = data;
  //   },
}
const getters = {
  //   getCompany: state => {
  //     return state.company;
  //   },
}
const actions = {
  async getShareConfig({ rootState, commit ,dispatch} , opt) { 
    let query = `
      query($type: Int,$scope: String,$name:String){
        settingsQuery{
          by(type: $type,scope:$scope,name:$name){
            description
            displayName
            id
            isDeleted
            isEncrypted
            name
            scope
            scopeType
            value
          }
        }
      }
    `; 
    let body = {
      query: query,
      variables:{
        type:2,
        scope:opt.id || rootState.exhibitionId,
        name:opt.name||'share.exhibition.config'
      }
    };
    let data = await dispatch('graphqlPostByZXS',body,{root:true});
    return new Promise((resolve, reject) => {
      if(data.data.settingsQuery.by){
        resolve(data.data.settingsQuery.by.value);
      } else {
        resolve(false);
      }
    })
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
} 
