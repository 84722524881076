import axios from 'axios';
import config from '@assets/config.json'; // api 请求json

const state = {}
const mutations = {}
const getters = {}
const actions = { 
	addAppointmentNumber({
		rootState,
		commit
	}, id) {
		return new Promise((resolve, reject) => {
			axios.post(`${config.api.api}/api/v3/exhibitorappointmentagora/add/number?exhibitorId=${id}`, {
				exhibitorId: id
			}, rootState.header).then(data => {
				if (data.data) {
					resolve(data.data);
				}
			}).catch((err) => {
				console.log(err);
			});
		});
	},
}
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}