import axios from 'axios';
import config from '@assets/config.json'; // api 请求json 

const state = {
  theme: '',
}
const mutations = {
  setTheme(state, data) {
    state.theme = data;
  },
}
const getters = {
  getTheme: state => {
    return state.theme;
  },
}
const actions = {
  // 添加浏览记录
  changeTheme({
    state,
    commit
  }, theme) {
    return new Promise((resolve, reject) => {
      window.document.documentElement.setAttribute('data-theme', theme);
      commit('setTheme', theme);
      resolve(true);
    });
  },
  setTheme({
    state,
    commit,
    dispatch,
    rootGetters
  }, id) {
    return new Promise((resolve, reject) => {
      if (rootGetters.getDisplay) {
        commit('setTheme', rootGetters.getDisplay);
        dispatch('changeTheme', rootGetters.getDisplay);
      }
      resolve(true);
    });
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}