/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import store from "../store/store";
import router from "./router";
import config from "../assets/config.json";
import { Message, Modal } from "view-design";

// let ReqApi = process.env.NODE_ENV === 'development' ? config.api.reqApi : config.testApi.reqApi;
let ReqApi = config.testApi.reqApi;
// axios 配置
axios.defaults.baseURL = ReqApi;
// http request 拦截器
axios.interceptors.request.use(
  config => {
    // if (store.state.refresh && store.state.login && store.getters.getTokenTime) {
    //   if (new Date().getTime() > store.getters.getTokenTime) {
    //     store.commit('setRefreshStatus', false, { root: true });
    //     store.commit('setTokenTime', '', { root: true });
    //     store.dispatch('refreshToken');
    //   }
    // }

    // 解决IE浏览器请求优先从缓存中获取的bug
    if (config.method === "get") {
      config.params = {
        t: Date.parse(new Date()) / 1000,
        ...config.params,
      };
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          if (!store.state.login) {
            Message.error({
              content: "请登录后操作",
              onClose: function() {},
            });
          } else {
            store.commit("setRedirectUrl", location.href);
            store.commit("setLogin", false);
            store.commit("setUser", "");
            store.commit("setUserId", "");
            store.commit("setMyAdvisorySetting", null);
            store.commit("setToken", "");
            store.commit("setTokenTime", "");
            Modal.confirm({
              title: "授权过期，请重新登陆",
              onOk: () => {
                if (store.state.loginType === 2) {
                  store.dispatch("signOutOidc");
                } else if (store.state.loginType === 3) {
                  store.dispatch("signOutOidcQQ");
                }
                store.dispatch("linkTo/linkToLogin");
              },
              onCancel: () => {
                router.push(`/home/index/${store.state.exhibitionId}`);
              },
            });
          }
          break;
        case 400:
          let errText = "400:请求接口有误";
          if (err.response.data && err.response.data.error_description === "invalid_username_or_password") {
            errText = "无效的用户名或密码！";
          }
          // console.log(err,'errerr400');
          if (err.response.data && err.response.data.error === "invalid_request") {
            errText = err.response.data.error_description;
          }
          Message.error(errText);
          break;
        case 404:
          router.push({
            path: "/404",
          });
          break;
        case 500:
          router.push({
            path: "/500",
          });
          break;
      }
    }
    return Promise.reject(err);
  }
);

// 封装请求
const get = (url, params) => {
  // 单独封装get请求
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then(function(response) {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};
const post = (url, params) => {
  // 单独封装post请求
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then(function(response) {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
};

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$http = axios;
