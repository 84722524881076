import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store';
import {
  liveRouter
} from './router-live'; // 主办路由  
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [{
      path: '/',
      redirect: 'home',
      name: 'home'
    },
    ...liveRouter,
  ]
});

// 路由守卫 
router.beforeEach((to, form, next) => {
  if (to.meta && to.meta.auth) {
    next();
  }
  let exOnid = to.query.exhibitionId || to.params.id;
  if (to.query && exOnid) {
    store.commit('setExhibitionId', exOnid);
    let opt = {
      exhibitionId: exOnid,
      target: 'exhibition',
      targetId: exOnid,
    }
    if (store.state.user && store.state.user.userId) {
      opt.userId = store.state.user.userId;
    }
    store.dispatch('addAllView', opt)
  }
  if (exOnid) {
    store.dispatch('loginModule/getExhibitionInfoByGraphal', exOnid).then((res) => {
      if (res && res.ico) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = res.ico;
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      next();
    });
  } else {
    next()
  }
});


router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;