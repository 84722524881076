import store from '../store/store'

export const hasRole = {
  install(Vue) {
    Vue.directive('hasRole', { // 判断是否有权限 如没有权限则添加提示
      inserted(el, binding, vnode) {
        let permissions = vnode.context.$store.state.roleModule.roleMenu
        let roleName = binding.value
        el.style.display = 'block';
        let flag = false
        if (permissions && permissions.indexOf(roleName) !== -1) {
          flag = true
        }
        if (!flag) {
          el.style.display = 'none';
          el.innerHTML = '';
          let tip = document.createElement('div');
          tip.innerHTML = `
            <div style="height: 500px;line-height: 500px;text-align: center;color: #ababab;">
              主办暂未开放此服务
            </div>
          `
          el.parentNode.appendChild(tip);
        }
      }
    })
    Vue.directive('hasRoleByEx', { // 判断是否有权限 如没有权限则添加提示(展商后台提示)
      inserted(el, binding, vnode) {
        let permissions = vnode.context.$store.state.roleModule.roleMenu
        let roleName = binding.value
        el.style.display = 'block';
        let flag = false
        if (permissions && permissions.indexOf(roleName) !== -1) {
          flag = true
        }
        if (!flag) {
          el.style.display = 'none';
          el.innerHTML = '';
          let tip = document.createElement('div');
          tip.innerHTML = `
            <div style="height: 500px;line-height: 500px;text-align: center;color: #ababab;">
              主办暂未开放此权限
            </div>
          `
          el.parentNode.appendChild(tip);
        }
      }
    })
    Vue.directive('hasRole2', { // 判断是否有权限 如没有权限则添加提示
      inserted(el, binding, vnode) {
        let permissions = vnode.context.$store.state.roleModule.roleMenu
        let roleName = binding.value
        let flag = false
        if (permissions && permissions.indexOf(roleName) !== -1) {
          flag = true
        }
        if (!flag) {
          if (el.parentNode) {
            let tip = document.createElement('div');
            tip.innerHTML = `
              <div style="height: 500px;line-height: 500px;text-align: center;color: #ababab;">
                您未开通此服务请联系展商秀客服,升级可享更多权限
              </div>
            `
            el.parentNode.replaceChild(tip, el);
          } else {
            el.innerHTML = `
              <div style="height: 500px;line-height: 500px;text-align: center;color: #ababab;">
                您未开通此服务请联系展商秀客服,升级可享更多权限
              </div>
            `
          }
        }
      }
    })
    Vue.directive('hasRoleShow', {
      bind(el, binding, vnode) {
        let permissions = vnode.context.$store.state.roleModule.roleMenu
        let roleName = binding.value
        let flag = false
        if (permissions && permissions.indexOf(roleName) !== -1) {
          flag = true
        }
        if (!flag) {
          el.style.display = 'none';
        }
      }
    })
  }
}