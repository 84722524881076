import axios from 'axios';
import config from '@assets/config.json'; // api 请求json 

const state = {
  role: null, // 展会权限角色
  roleInfo: null,
  roleMenu: null,
}
const mutations = {
  setRoleInfo(state, data) {
    state.roleInfo = data;
  },
  setRole(state, data) {
    state.role = data;
  },
  setRoleMenu(state, data) {
    state.roleMenu = data;
  },
}
const getters = {
  getRoleInfo: state => {
    return state.roleInfo;
  },
  getRole: state => {
    return state.role;
  },
  getRoleMenu: state => {
    return state.roleMenu;
  },
}
const actions = {
  getRoleHandle({
    state,
  }, roleName) {
    // 获取权限 
    let hasRole = false;
    if (state.roleMenu && state.roleMenu.indexOf(roleName) !== -1) {
      hasRole = true;
    }
    if (!hasRole) {
      that.$Message.destroy();
      that.$Message.warning({
        content: "您未开通此服务请联系展商秀客服",
        duration: 2
      });
    }
    console.log('roleName', roleName);
    console.log('hasRole', role);
    return hasRole;
  },
  getVersionExhibition({
    rootState,
    commit
  }) {
    // 获取权限 
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v3/version/version-exhibition?exhibitionId=${rootState.exhibitionId}`, rootState.header).then(({
        data
      }) => {
        if (data) {
          commit('setRoleInfo', data);
          commit('setRole', data.versionId);
          resolve(data);
        } else {
          resolve(null);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  getVersionDisplay({
    rootState,
    commit
  }) {
    // 获取权限 
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v3/version/display/feature/exhibitionid/${rootState.exhibitionId}`, rootState.header).then(({
        data
      }) => {
        if (data.data) {
          commit('setRoleMenu', data.data);
          resolve(data.data);
        } else {
          resolve(null);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  //dispatch('roleModule/getUserAdvisorySetting',{}, {root: true}); // 通过userId展会Id获取展会咨询设置 
  async getUserAdvisorySetting({
    rootState,
    rootGetters,
    dispatch,
    commit
  }) {
    let query=`
    {
      inMemberQuery {
        advisoryDayTotal(exhibitionId: "${rootGetters.getExhibitionId}",userId:"${rootState.user.userId}") {
          userId
          appointmentDayNumber
          enquiryDayNumber
          agroaDayNumber
        }
      }
    }
    `
    let body={
      query:query,
      variables:{},
    }
    let data = await dispatch('graphqlPostByZXS', body, {
      root: true
    });
    // console.log(data,'调用个人的次数')
    return new Promise((resolve, reject) => {
      if (data.data){
        let res=JSON.parse(JSON.stringify(data.data.inMemberQuery.advisoryDayTotal))
        // console.log(res,'个人的次数')
        if(res){
          commit("setMyAdvisorySetting", res, {
            root: true
          });
          resolve(res)
        }else{
          reject('')
        }
      }else{
        reject('')
      }
    });
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}